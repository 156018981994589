/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { DiValImageWithFallback } from '@/components/blocks/DiValMaterialImage';
import { Linkable } from '@/components/blocks/Linkable';
import { ProgressIndicator } from '@/components/blocks/ProgressIndicator';
import { categoryContainerSX } from '@/components/content/Category/styles/container';
import { categoryImageSX } from '@/components/content/Category/styles/image';
import { useCategory } from '@/data/Content/Category';
import { useNextRouter } from '@/data/Content/_NextRouter';
import { useUser } from '@/data/User';
import { ContentContext } from '@/data/context/content';
import { ID } from '@/data/types/Basic';
import { CategoryType } from '@/data/types/Category';
import { getContractIdFromContext } from '@/utils/getContractIdFromContext';
import { Box, Paper, Stack, Typography } from '@mui/material';
import { FC, MouseEvent, useCallback, useContext, useEffect } from 'react';

export const Category: FC<{
	id: ID;
	clickAction?: (event?: MouseEvent) => void;
}> = ({ id, clickAction }) => {
	const router = useNextRouter();
	const { rawData, category, loading } = useCategory(id);
	const { user } = useUser();
	const contract = getContractIdFromContext(user?.context);
	const { onNotify } = useContext(ContentContext) as {
		onNotify: (id: ID, contract: string, category?: CategoryType) => void;
	};
	const onClick = useCallback(
		(event: MouseEvent) => {
			if (clickAction) {
				clickAction(event);
			}
			router.push(category?.seo.href as string);
		},
		[category?.seo.href, clickAction, router]
	);

	useEffect(() => {
		if (onNotify && rawData) {
			onNotify(id, contract, category);
		}
	}, [loading, rawData, category]); // eslint-disable-line react-hooks/exhaustive-deps
	return loading ? (
		<ProgressIndicator />
	) : category ? (
		// TODO Hoist href to top level category object, presentation shouldn't have to handle this structure.

		<Paper sx={categoryContainerSX} onClick={onClick} id="category-container-paper">
			<Stack direction="column" justifyContent="space-between" alignItems="center" width="100%">
				<Box
					px={2}
					py={1}
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						flexBasis: '100%',
						width: '100%',
						backgroundColor: 'background.dark',
						color: 'white',
						'div#category-container-paper:hover &': { backgroundColor: 'background.main' },
					}}
				>
					<Typography
						component={Linkable}
						variant="h5"
						href={category.seo.href || ''}
						data-testid={category.seo.href || ''}
						id={category.seo.href || ''}
						sx={{
							color: 'white',
							textAlign: 'center',
							'&:hover, &:active, &:focus': { color: 'white', textDecoration: 'none' },
						}}
					>
						{category.name}
					</Typography>
				</Box>
				<Box width="100%" display="flex" justifyContent="center">
					<DiValImageWithFallback
						width="100%"
						alt={category.shortDescription}
						src={category.fullImage || category.thumbnail || ''}
						sx={categoryImageSX}
						imageType="thumbnail"
					/>
				</Box>
			</Stack>
		</Paper>
	) : null;
};
