/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { Img } from '@/components/blocks/MaterialImage';
import { useDiValImagePath } from '@/data/Content/DiValImagePath';
import { combineSX } from '@/utils/combineSX';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ComponentProps, useMemo, useState } from 'react';

const StyledImg = styled('img')({});

export const DiValImageWithFallback = (
	props: { imageType: string } & ComponentProps<typeof StyledImg>
) => {
	const { src, srcSet, sx, imageType, ...rest } = props;

	const { thumbnail, thumbnail2x, fullImage, fullImage2x, pdpThumbnail, pdpThumbnail2x } =
		useDiValImagePath(src);

	const { imageSrc, imageSrc2x } = useMemo(() => {
		if (imageType === 'thumbnail') {
			return { imageSrc: thumbnail, imageSrc2x: thumbnail2x };
		} else if (imageType === 'fullImage') {
			return { imageSrc: fullImage, imageSrc2x: fullImage2x };
		} else if (imageType === 'pdpThumbnail') {
			return { imageSrc: pdpThumbnail, imageSrc2x: pdpThumbnail2x };
		} else {
			return { imageSrc: thumbnail, imageSrc2x: thumbnail2x };
		}
	}, [fullImage, fullImage2x, imageType, pdpThumbnail, pdpThumbnail2x, thumbnail, thumbnail2x]);

	const [hasError, setHasError] = useState(src ? false : true);

	return hasError ? (
		<Box sx={combineSX([sx, { width: '100%' }])}>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					maxWidth: '140px',
					maxHeight: '140px',
					width: '100%',
					padding: '50% 0',
					backgroundColor: '#bdbdbd',
				}}
			>
				<PhotoCameraIcon
					sx={{
						color: 'background.default',
					}}
				/>
			</Box>
		</Box>
	) : (
		<Img
			{...rest}
			sx={sx}
			src={imageSrc}
			srcSet={`${imageSrc2x} 2x`}
			onError={() => setHasError(true)}
		/>
	);
};
